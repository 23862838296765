<template>
	<div class="cashflow">
		<!-- <div style="height: 0.4rem; background-color: #FFFFFF;"></div> -->
		<!-- <common-head :title="$t('system.TransactionRecord')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('system.TransactionRecord')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<ul class="commonRouter" :class="tabActive == 0 ? 'active-l' : 'active-r'">
				<li :class="tabActive == 0 ? 'item active' : 'item'" @click="routerTo(0)">{{ $t('system.trade') }}</li>
				<li :class="tabActive == 1 ? 'item active' : 'item'" @click="routerTo(1)">{{ $t('system.Log') }}</li>
			</ul>
			<div class="commonTabtit">
				<ul class="type">
					<li :class="typeActive == -1 ? 'active' : ''" @click="handleTabType(-1)">{{ $t('pledge.all') }}</li>
					<li :class="typeActive == 1 ? 'active' : ''" @click="handleTabType(1)">{{ $t('system.Buyin') }}</li>
					<li :class="typeActive == 0 ? 'active' : ''" @click="handleTabType(0)">{{ $t('system.Sell') }}</li>
				</ul>
				<div class="img" @click="tan()">
					<img  src="@/assets/imgs/new_color/shaixuan.png" alt="" />
				</div>
				<!-- <div class="exchange" @click="showPicker = true">{{ exchange }} <i class="icon_arr_up"></i></div> -->
				<!-- <div class="exchange" v-if="exchange == 'bian'" @click="showPicker = true">BINANCE <i class="icon_arr_up"></i></div>
				<div class="exchange" v-else @click="showPicker = true">{{ exchange }} <i class="icon_arr_up"></i></div> -->
				<div class="tan" v-if="tan_show" ref="tan_top">
					<div class="content">
						<div class="head_1">{{$t('system.exchange')}}</div>
						<div class="exe_list1">
							<div class="item" :class="{'active':isActive=='bitrocket'}" @click="isActive = 'bitrocket'">{{$t('system.bitrocket')}}</div>
							<div class="item" :class="{'active':isActive=='okex'}" @click="isActive = 'okex'">{{$t('system.OKEx')}}</div>
							<div class="item" :class="{'active':isActive=='bian'}" @click="isActive = 'bian'">{{$t('system.bian')}}</div>
							<div class="item" :class="{'active':isActive=='huobi'}" @click="isActive = 'huobi'">{{$t('system.huobi')}}</div>
							<!-- <div class="item" :class="{'active':isActive=='uniswap'}" @click="isActive = 'uniswap'">UNISWAP</div> -->
						</div>
						<div class="head_1">{{$t('system.jiaoyileixin')}}</div>
						<div class="exe_list1">
							<div class="item" :class="{'active':fangshi=='spot'}" @click="fangshi = 'spot'">{{$t('system.Spot')}}</div>
							<div class="item" :class="{'active':fangshi=='swap'}" @click="fangshi = 'swap'">{{$t('system.contract')}}</div>
						</div>
						<div class="head_2">{{$t('system.DealFor')}}</div>
						<div class="dui_list">
							<div class="input">
								<input type="txt" name="" id="" value="" v-model="num1" :placeholder="$t('system.Pleaseentercurrency')"/>
							</div>
							<div class="fenge"></div>
							<div class="input">
								<input type="txt" name="" id="" value="" v-model="num2" :placeholder="$t('system.Pleaseentercurrency')"/>
							</div>
						</div>
						<div class="button">
							<div class="button_1" @click="quxiao()">{{ $t('system.cancel') }}</div>
							<div class="button_2" @click="quedin()">{{ $t('system.yes') }}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="layoutBox bgWhite"> -->
			<div class="layoutBox">
				<van-list v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')" @load="onLoadcashFlow">
					<div class="bill-list">
						<bill-list v-for="(item, index) in billList" :key="index" :list="item" :exchange="isActive"></bill-list>
					</div>
				</van-list>
			</div>
			
			<van-overlay :show="show" @click="zhezhao()" z-index="98" class-name="zhezhao"></van-overlay>
			<!-- 弹出交易所选择 -->
			<!-- <van-action-sheet
				  v-model="showPicker"
				  :round="false"
				  :actions="periodChoiceSet"
				  @select="onSelectPeriod"
				></van-action-sheet> -->
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue';
	import { Overlay } from 'vant';
	
	Vue.use(Overlay);
// import commonHead from '@/components/commonHead';
import billList from './components/billList';
export default {
	name: 'transactionRecords',
	components: {
		// commonHead,
		billList,
	},
	props:["tabActive"],
	data() {
		return {
			num1: '',
			num2: '',
			num3: '_swap',
			show: false,
			tan_show: false,
			isActive: '',
			showOfFalse: true,
			
			// 默认选中
			active: 1,
			// 定义一个切换后的类样式
			qiehuanqian: 'tanChuCengDivStyle',
			qiehuanhou: 'tanChuCengDivStyle1',
			changeStyle: 'dingDanStyle2',
			dingDanList: [
				{
					name: '全部',
					index: '1'
				},
				{
					name: '买入',
					index: '2'
				},
				{
					name: '卖出',
					index: '3'
				}
			],
			radio: '1',
			value: '',
			jiaoYiSuo: '',
			columns: ['火必', '币安'],

			// exchange: '',
			// 选择币种类型查看
			showLocal: false,
			// 选择币种类型查看
			// showPicker: false,
			// showPicker: true,

			billLoading: false,
			billFinished: false,
			billList: [],
			billPage: 1,
			total_profit: 0,
			today_profit: 0,

			// 第一个input的显示或者隐藏
			yesOrNo1: false,
			// 相关配置
			timeChangeInput1: '',
			date1: '1',
			type: -1, //所有订单交易记录

			// 第二个input的显示或者隐藏
			yesOrNo2: false,
			// 相关配置
			timeChangeInput2: '',
			title: '',
			//----------------20200410[start]------
			// tabActive: 0,
			typeActive: -1,
			fangshi: '',
			// 20210415
			// periodChoiceSet: [
			// 	{ name: 'HUOBI', value: 'huobi' },
			// 	{ name: 'BINANCE', value: 'bian' },
			// 	{ name: 'OKX', value: 'okex' },
			// ],
		};
	},
	// filters: {
	// 	typeName(type) {
	// 		if (type == 1) {
	// 			return '买入';
	// 			// return this.$t('system.Buyin')
	// 		}
	// 		if (type == 0) {
	// 			return '卖出';
	// 			// return this.$t('system.Sell')
	// 		}
	// 	},
	// 	exchangeNameFilter(web) {
	// 		if (web == 'huobi') {
	// 			return 'Huobi';
	// 		} else if (web == 'bian') {
	// 			return 'Binance';
	// 		}
	// 	}
	// },
	// watch: {
	// 	take(news, old) {
	// 		this.billPage = 1;
	// 		this.billFinished = false;
	// 		this.billList = [];
	// 	}
	// },
	created() {
		let userinfo = JSON.parse(localStorage.getItem('userInfo'))
		// this.userInfo = userinfo
		// console.log(userinfo)
		// 获取默认交易所信息
		this.isActive = userinfo.default_exchange;
		// console.log(this.$route.query.exchange);
		if(this.$route.query.exchange) {
			if(this.$route.query.exchange=='ok'){
				this.isActive='okex'
			}else{
				this.isActive = this.$route.query.exchange
			}
		}
		if(this.$route.query.fangshi) {
			if(this.$route.query.fangshi == 1) {
				this.fangshi = 'spot'
			}else if(this.$route.query.fangshi == 2) {
				this.fangshi = 'swap'
			}else {
				this.fangshi = this.$route.query.fangshi
			}
		}
		if(this.$route.query.currency_pair) {
			this.num1 = this.$route.query.currency_pair.split('/')[0]
			this.num2 = this.$route.query.currency_pair.split('/')[1]
		}
		if(this.$route.query.num1 && this.$route.query.num2) {
			this.num1 = this.$route.query.num1
			this.num2 = this.$route.query.num2
		}
		// this.getBillList();
		this.billPage = 1;
		// this.title = '交易记录(' + this.getExchangeName(this.exchange) + ')';
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		tan() {
			this.show = !this.show;
			setTimeout(() =>{
				this.tan_show = !this.tan_show;
			},100)
		},
		quxiao() {
			this.show = !this.show;
			let userinfo = JSON.parse(localStorage.getItem('userInfo'));
			this.isActive = userinfo.default_exchange;
			this.fangshi = '';
			setTimeout(() =>{
				this.tan_show = !this.tan_show;
			},100)
		},
		quedin() {
			this.show = !this.show;
			this.onSelectPeriod();
			setTimeout(() =>{
				this.tan_show = !this.tan_show;
			},100)
		},
		zhezhao() {
			this.show = false;
			setTimeout(() =>{
				this.tan_show = false;
			},100)
		},
		// 选择交易所弹出框,赋值，更新列表初始化为空
		onSelectPeriod() {			
		  this.billPage = 1;
		  this.billFinished = false;
		  this.billList = [];
		  this.billLoading = true;
		  // this.showPicker = false;
		  this.onLoadcashFlow();
		},
		
		// ----------20200412[start]-------
		routerTo(index) {
			if (index !== this.tabActive) {
				this.$emit('switchTab',index)
				// if (index == 0) {
				// 	this.$router.push('/transactionRecords')
				// } else {
				// 	this.$router.push({
				// 		path: '/journal',
				// 		query: {
				// 			exchange: this.isActive,
				// 			fangshi: this.fangshi,
				// 			num1: this.num1,
				// 			num2: this.num2
				// 		}
				// 	});
				// }
			}
		},
		
		// 切换交易记录类型-全部-1/买入1/卖出0
		handleTabType(index) {
			this.typeActive = index
			this.billPage = 1;
			this.billFinished = false;
			this.billList = [];
			this.billLoading = true;
			this.onLoadcashFlow();
		},
		// ----------20200412[end]-------
		
		// 获取交易记录列表
		getBillList() {
			let pair = ''
			if(this.fangshi == 'spot'){
				pair = this.num1 + '_' + this.num2
			}else if(this.fangshi == 'swap' && this.num1 && this.num2){
				pair = this.num1 + '_' + this.num2 + this.num3
			}
			this.$post2('Robot/api/index/getOrderLogsV2', {
				page: this.billPage++,
				exchange: this.isActive,
				// type: this.type,
				type: this.typeActive,
				currency: pair,
				ssf: this.fangshi,
			})
			.then(res => {
				// 异步充值记录
				this.billList.push(...res.list);
				// 加载状态结束
				this.billLoading = false;
				// 数据全部加载完成
								
				if (res.list.length < 1) {
					this.billFinished = true;
				}
			})
			.catch(e => {
				this.billFinished = true;
				this.billLoading = false;
				this.$toast.fail(e);
			});
		},
		
		// 滚动加载交易记录列表
		onLoadcashFlow() {
			this.getBillList();
		}
	}
};
</script>

<style lang="less" scoped>
.jiaoYiRadioStyle {
	padding-left: 0.2rem;
}

.chongZhiStyle {
	width: 3.4rem;
}

.queDingStyle {
	background: #90C4FF;
	border: 0rem;
	width: 3.4rem;
}

.tanchuInputStyle {
	padding: 0.1rem;
	background: #f1f1f3;
	border: 0rem;
	border-radius: 5px;
}

.inputStyles {
	width: 100%;
}

.pNieStyle {
	margin-top: 0.1rem;
	padding-left: 0.2rem;
	font-size: 0.1rem;
	color: #948690;
}

.van-field {
	padding: 0.1rem;
	width: 3.5rem;
}

.vanFiedlStyle {
	border-radius: 5px;
	background: #f1f1f3;
	// height: 0.5rem;
}

// .radioStyle{
//   background: red;
// }
.dingDanStyle2 {
	margin: 0.1rem;
	color: #ecbb34;
}

.dingDanStyle {
	margin: 0.3rem;
	color: #999999;
}

.tanChuCengDivStyle1 {
	text-align: center;
	background: #90C4FF;
	border-radius: 5px;
	padding: 0.17rem;
	color: #ffffff;
}

.tanChuCengDivStyle {
	text-align: center;
	background: #f1f1f3;
	border-radius: 5px;
	padding: 0.17rem;
	// color: #ffffff;
}

.tanChuCeng {
	padding: 0.3rem;
}

.titleBarStyle {
	position: absolute;
	top: 0rem;
	// margin-bottom: 1rem;
	width: 100%;
}

.showOrHiden {
	// margin: 0 auto;
	position: absolute;
	top: 0.9rem;
	border-radius: 7px;
	width: 92%;
	margin-left: 0.3rem;
	// margin-right: 1.3rem;
}

.buyInTo {
	color: green;
	padding-right: 0.2rem;
}

.firstLiStyle {
	font-size: .28rem;
	background: #F5F5F5;
	border-radius: .12rem;
	margin-bottom: 0.25rem;
}

.spanOfdiv {
	margin-bottom: 0.1rem;
	height: 1rem;
	width: 1rem;
	background: #f3f0f3;
	border-radius: 50px;
}

.ulStyleOfTrans {
	margin-left: 1rem;
	// margin-right: 1rem;
}

.spanStyleInTransac {
	margin-top: 0.2rem;
}

.divBorderStyle {
	width: 1.2rem;
	height: 1.2rem;
	background: red;
	border-radius: 50px;
}

.bodySpanStyle {
	color: #ff4b04;
}

.bodyLiStyle {
	margin: 0.1rem;
	color: #86878d;
	font-size: 15px;
}

.bodyDivStyle {
	border-radius: 5px;
	background: #ffffff;
	margin: 0.3rem;
	padding: 0.2rem;
}

.titlePStlye {
	margin-top: 0.3rem;
	padding-left: 0.1rem;
	font-size: 0.2rem;
	color: #ffffff;
}

.vanBodyCol {
	border-radius: 5px;
	height: 1.7rem;
	margin: 0.2rem;
	border: 1px solid #999999;
	background: #ecbb34;
}

.bodyPStyle {
	margin-top: 0.2rem;
	font-size: 0.4rem;
	color: #ffffff;
	padding-left: 0.1rem;
}

// .bodyVanRow {
//   text-align: center;
// }
.titleVanRowDivStyle {
	width: 100%;
	padding: 5px;
	background: #ffffff;
}

.titleVanStyle {
	background: red;
	height: 2rem;
	border-radius: 5px;
}

s .colorStyle {
	color: #999999;
}

.btnFdivStyle {
	text-align: center;
}

.btnStyle {
	margin-top: 1rem;
	width: 4.2rem;
}

.inputStyle {
	border-top: 0;
	border-left: 0;
	border-right: 0;
	width: 100%;
	border-bottom: 1px solid #f2f2f2;
	margin-top: 0.1rem;
	margin-bottom: 0.1rem;
	color: #8b7575;
	padding: 0.1rem;
}

.fontStyle {
	font-size: 0.1rem;
	color: #9b9b9b;
	margin: 0.3rem;
}

.iconStyle {
	margin-right: 0.15rem;
}

.ulList > li {
	padding: 0.3rem;
	margin: 0.3rem;
	background: #ffffff;
	border-radius: 5px;
}

.listStyle {
	background: #ffffff;
	border-radius: 5px;
}

.roleBtnStyle {
	margin-left: 0.4rem;
	margin-right: 0.4rem;
	border-radius: 5px;
}

.cashflow {
	// 我发出的
	// width: 100%;
	// background: #f3f0f3;
	// height: 100%;
	// overflow-y: auto;
	font-family: PingFangSC-Regular;

	.cash {
		padding: 0.3rem 0.5rem;
		border-bottom: 1px solid var(--bdColor);

		.cash_text {
			.g {
				color: #999;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}
		}

		.cash_text2 {
			width: 3.2rem;
			text-align: center;
			margin: 0 auto;
			height: 0.74rem;
			line-height: 0.74rem;
			font-size: 0.26rem;
			margin-top: 0.4rem;
			border-radius: 2.5px;
			color: #ffffff;
			background: #2f83ff;
		}
	}
}
</style>

<!-- 20200412[start] -->
<style scoped lang="less">
	.commonRouter {
		padding: 0.4rem 0.4rem 0.3rem 0.4rem;
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		li {
			color: #EFBA0D;
			font-size: .32rem;
			width: 3.2rem;
			height:0.68rem;
			border: 1px solid #EFBA0D;
			border-radius: 0.16rem;
			font-weight: bold;
			// border: 2px solid #FFFFFF;
			// border-radius: .36rem;
			display: flex;
			justify-content: center;
			align-items: center;
			&.active {
				color: #FFFFFF;
				background: #FAD538;
				border: none;
			}
		}
	}
	
	.layoutBox {
		padding: 0 .2rem;
		min-height: calc(100vh - 2.1rem);
		position: relative;
	}
	
	.commonTabtit {
		position: relative;
		padding: 0 0.4rem;
		color: #6F7A8B;
		background-color: #FFFFFF;
		font-size: .28rem;
		height: 0.66rem;
		display: flex;
		justify-content: space-between;
		// justify-content: flex-end;
		align-items: center;
		margin-bottom: .2rem;
		border-bottom: 0.05rem solid #FFFFFF;
		.type {
			display: flex;
			align-items: center;
			li {
				line-height: 0.66rem;
				margin-right: .5rem;
				&.active {
					color: #333333;
					font-weight: bold;
					border-bottom: 0.05rem solid #EFBA0D;
				}
			}
		}
		.img {
			img {
				width: 0.38rem;
				height: 0.36rem;
			}
		}
		.exchange {
			height: .8rem;
			display: flex;
			align-items: center;
			text-transform: uppercase;
			.icon_arr_up {
				width: .1rem;
				height: .1rem;
				border-right: 2px solid #666666;
				border-bottom: 2px solid #666666;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				margin-left: .15rem;
				display: inline-block;
			}
		}
		.tan {
			z-index: 99;
			position: absolute;
			right: 0;
			top: 0.59rem;
			background-color: #FFFFFF;
			width: 100%;
			// height: 6.4rem;
			.content {
				margin: 0.3rem 0.4rem;
				.head_1 {
					color: #333333;
					font-size: 0.28rem;
					margin-bottom: 0.16rem;
				}
				.exe_list {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 0.32rem;
					.item {
						font-weight: bold;
						padding: 0.06rem 0.18rem;
						background-color: #F9FAFB;
						color: #999999;
						text-align: center;
						border-radius: 0.04rem;
					}
					.active {
						background-color: #E7ECEF;
						color: #333333;
						position: relative;
					}
					// .active:before {
					//         content: '';
					//         position: absolute;
					//         right: 0;
					//         bottom: 0;
					//         border: 0.12rem solid #1677FF;
					//         border-top-color: transparent;
					//         border-left-color: transparent;
					// }
					// .active:after {
					//     content: '';
					// 	width: 1px;
					// 	height: 3px;
					// 	position: absolute;
					// 	right: 1px;
					// 	bottom: 1px;
					// 	border: 2px solid #fff;
					// 	border-top-color: transparent;
					// 	border-left-color: transparent;
					// 	transform: rotate(45deg);
					// }
				}
				.exe_list1 {
					display: flex;
					align-items: center;
					// justify-content: space-between;
					flex-wrap: wrap;
					margin-bottom: 0.32rem;
					.item {
						font-weight: bold;
						padding: 0.06rem 0.34rem;
						background-color: #F9FAFB;
						color: #999999;
						text-align: center;
						margin-right: 0.5rem;
						border-radius: 0.04rem;
						margin-bottom: 0.2rem;
						&:last-child {
							margin-right: 0;
						}
					}
					.active {
						background-color: #E7ECEF;
						color: #333333;
						position: relative;
					}
					// .active:before {
					//         content: '';
					//         position: absolute;
					//         right: 0;
					//         bottom: 0;
					//         border: 0.12rem solid #1677FF;
					//         border-top-color: transparent;
					//         border-left-color: transparent;
					// }
					// .active:after {
					//     content: '';
					// 	width: 1px;
					// 	height: 3px;
					// 	position: absolute;
					// 	right: 1px;
					// 	bottom: 1px;
					// 	border: 2px solid #fff;
					// 	border-top-color: transparent;
					// 	border-left-color: transparent;
					// 	transform: rotate(45deg);
					// }
				}
				.head_2 {
					color: #373737;
					font-size: 0.32rem;
					margin-bottom: 0.38rem;
				}
				.dui_list {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 0.48rem;
					.input {
						// border-bottom: 0.02rem solid #2568E4;
						input {
							border: none;
							text-align: center;
							font-size: 0.24rem;
							transform: scale(0.91);
							color: #999999;
							padding: 0.2rem 0;
							background-color: #F9FAFB;
							padding: 0.19rem 0.255rem;
							margin-left: -0.12rem;
							border-radius: 0.08rem;
						}
					}
					.fenge {
						width: 0.04rem;
						height: 0.51rem;
						background-color: #EFBA0D;
					}
				}
				.button {
					display: flex;
					justify-content: space-between;
					.button_1 {
						background-color: #E7ECEF;
						color: #333333;
						font-size: .32rem;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 3.2rem;
						height: 0.96rem;
						border-radius: 0.16rem;
						// background-repeat: no-repeat;
						// background-position: center center;
						// background-size: 100% 100%;
						// background-image: url(../..//assets/imgs/bag-w.png);
					}
					.button_2 {
						background-color: #FAD538;
						color: #333333;
						font-size: .32rem;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 3.2rem;
						height: 0.96rem;
						border-radius: 0.16rem;
						// background-repeat: no-repeat;
						// background-position: center center;
						// background-size: 100% 100%;
						// background-image: url(../..//assets/imgs/bag-b.png);
					}
				}
			}
		}
	}
	
	// .bill-list-item {
	// 	color: #666666;
	// 	font-size: .28rem;
	// 	background: #F5F5F5;
	// 	border-radius: .12rem;
	// 	margin-bottom: 0.25rem;
	// 	.hd {
	// 		padding: .25rem .2rem;
	// 		.caption {
	// 			height: .4rem;
	// 			display: flex;
	// 			justify-content: space-between;
	// 			align-items: center;
	// 			margin-bottom: .25rem;
	// 			.yen {
	// 				color: #373737;
	// 				margin-right: .25rem;
	// 			}
	// 			.type {
	// 				font-size: .24rem;
	// 			}
	// 		}
	// 		.specialFont {
	// 			color: #7675D8 !important;
	// 			text-transform: uppercase;
	// 		}
	// 		.info {
	// 			display: flex;
	// 			justify-content: space-between;
	// 			align-items: center;
	// 			height: .4rem;
	// 			.date {
	// 				color: #373737;
	// 				font-size: .24rem;
	// 			}
	// 		}
	// 	}
	// 	.bd {
	// 		color: #373737;
	// 		padding: .5rem .2rem  0;
	// 		li {
	// 			margin-bottom: .25rem;
	// 		}
	// 		span {
	// 			color: #666666;
	// 			width: 1.65rem;
	// 			display: inline-block;
	// 		}
	// 	}
	// 	.fd {
	// 		height: .5rem;
	// 		position: relative;
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;
	// 		&::before {
	// 			content: '';
	// 			position: absolute;
	// 			z-index: 1;
	// 			left: 0;
	// 			right: 0;
	// 			top: 50%;
	// 			border-top: 1px solid #D8D8D8;
	// 		}
	// 		img {
	// 			width: .24rem;
	// 			height: .24rem;
	// 			display: block;
	// 			background: #F5F5F5;
	// 			position: relative;
	// 			z-index: 2;
	// 			&.up {
	// 				-webkit-transform: rotate(180deg);
	// 				transform: rotate(180deg);
	// 			}
	// 		}
	// 	}
		
	// }
	.van-list {
		z-index: -99 !important;
	}
	.zhezhao {
		// height: 100vh;
		top: 22vh;
		top: calc(env(safe-area-inset-top) + 22vh);
	}
</style>
<!-- 20200412[end] -->
