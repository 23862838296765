<template>
	<div class="registBox">
		<van-nav-bar safe-area-inset-top :border="false">
			<template #left>
				<div class="langBtn" @click="handleChangeLang">
					<div class="icon"><img src="@/assets/imgs/new_color/yuyanqiehuan.png" alt="" /></div>
				</div>
			</template>
			<!-- <template #right>
				<div class="zhuce" @click="routerToLogin">
					<div class="txt">{{ $t('login.xiazai') }}</div>
				</div>
			</template> -->
		</van-nav-bar>

		<div class="registBody">
			<div class="headIcon"><img v-if="logo_img" :src="logo_img" alt="" /></div>
			<div class="form">
				<div class="login-dex">
					<ul class="c-reg-panel">
<!-- 						<li>
							<div class="c-reg-panel-leftbox">
								<van-row>
									<van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_mobilephone.png"
											alt=""
										/>
									</van-col>
									<van-col span="20"><input type="text" v-model="dataForm.username" :placeholder="$t('system.PleaseEnterYourUsername')" /></van-col>
								</van-row>
							</div>
						</li> -->
						<li>
							<div class="c-reg-panel-leftbox" style="display: flex">
								<!-- <country-code-selector :countryCode.sync="phoneCountry" style="padding: 0;color: #333333;width: 135px;"></country-code-selector> -->
								<input type="text" v-model="dataForm.phone" :placeholder="$t('login.PleaseEnterYourCellEmailNumber')" />
							</div>
							<div class="c-reg-panel-rightbox">
								<img :src="rigthImg" v-show="!errorInputShow && userInput" />
								<img :src="errorImg" v-show="errorInputShow" />
							</div>
						</li>
						<li>
							<div class="c-reg-panel-leftbox posRelat">
								<van-row>
									<!-- <van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_verificationcode.png"
											alt=""
										/>
									</van-col> -->
									<van-col span="20">
										<input type="text" v-model="dataForm.code" :placeholder="$t('login.PleaseEnterVerificationCode')" />
										<div class="getCodeBtn">
											<button href="javascript:;" @click="smscode" :disabled="codeSending">
												<span v-if="codeSending">({{ theTime }})</span>
												<span v-else>{{ $t('sPhone.getCode') }}</span>
											</button>
										</div>
									</van-col>
								</van-row>
							</div>
						</li>
						<li>
							<div class="c-reg-panel-leftbox">
								<van-row>
									<!-- <van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_password.png"
											alt=""
										/>
									</van-col> -->
									<van-col span="24"><input type="password" v-model="dataForm.password" :placeholder="$t('login.PleaseEnterYourPassword')" /></van-col>
								</van-row>
							</div>
						</li>
						<li>
							<div class="c-reg-panel-leftbox">
								<van-row>
									<!-- <van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_password.png"
											alt=""
										/>
									</van-col> -->
									<van-col span="20"><input type="password" v-model="dataForm.repassword" :placeholder="$t('login.PleaseEnterYourPasswordAgain')" /></van-col>
								</van-row>
							</div>
						</li>
						<li>
							<div class="c-reg-panel-leftbox">
								<van-row>
									<!-- <van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_Invitationcode.png"
											alt=""
										/>
									</van-col> -->
									<van-col span="20"><input type="text" v-model="dataForm.refcode" :placeholder="$t('login.PleaseEnterYourCode')" /></van-col>
								</van-row>
							</div>
						</li>
					</ul>
					<van-checkbox class="agreelaw" v-model="dataForm.agree">
						<template #icon="props">
							<img class="img-icon" style="width: 0.24rem" :src="require('@/assets/imgs/new_color/' + (props.checked ? 'check' : 'icon_unchoose') + '.png')" />
						</template>
						<!-- <span style="color: #c3c5c8;" @click="viewUserRegisterProtocol">{{ $t('login.AgreeLaw') }}</span> -->
						<span style="color: #c3c5c8;">{{ $t('login.AgreeLaw') }}</span><span class="mainColor1" @click.stop="routerTo('/userLaw')">{{ $t("login.userLaw") }}</span><span style="color: #c3c5c8;">{{ $t('login.and') }}</span><span class="mainColor1" @click.stop="routerTo('/userLaw1')">{{ $t("login.mianzetiaokuan") }}</span>
					</van-checkbox>
					<!-- <button class="loginButtonQ mainBackColor1" @click="doRegister('user/api/passport/register')">{{ $t('login.regist') }}</button> -->
					<button class="loginButtonQ mainBackColor1" @click="doRegister('user/api/passport/registerWithEmail')">{{ $t('login.regist') }}</button>
					<p class="tologin" @click="routerTo('/login')">
						{{ $t('login.hasAccount') }}
						<span class="mainColor1">{{ $t('login.login') }}</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import PuzzleVerification from 'vue-puzzle-verification';
import countryCodeSelector from 'vue-country-code-selector';

export default {
	name: 'registerPage',
	components: { 
		countryCodeSelector, 
		PuzzleVerification, 
	},
	data() {
		return {
			typeRadop: false,
			articleId: 0,
			dataForm: {
				nation: 86,
				username: '', //用户名
				password: '', // 密码
				repassword: '', // 确认密码
				refcode: '', // 邀请码
				sid: '', //短信验证码id
				code: '', //验证码
				phone: '', //手机号
				agree: false, //同意协议
			},
			active: 0,
			is_not_app: true,
			zone_phone: false,
			zone_email: false,
			zone_user: true,
			codeSending: false,
			theTime: 60,
			haveValue: false,
			// ----20200409----
			inputCode: '',
			haveCode: false,
			phoneCountry: 86,
			errorInputShow: false,
			userInput: false,
			rigthImg: require('@/assets/imgs/login/login-right.png'),
			errorImg: require('@/assets/imgs/login/login-error.png'),
			logo_img: '',
			registerIng:false
		};
	},
	created() {
		this.GetSystemImgList()
		if (this.$route.query.refcode) {
			this.dataForm.refcode = this.$route.query.refcode;
		}
		if (window.TPUBox) {
			this.is_not_app = false;
		}
		// 用户协议查看
		this.getAritcleId();
	},
	methods: {
		// 前往登录页面
		routerToLogin() {
			this.$router.push('/download');
		},
		handleChangeLang() {
			let lang = this.$i18n.locale
			// console.log('handleChangeLang', lang)
			if (lang === 'zh') {
				this.$i18n.locale = 'zhf'
				localStorage.setItem('lang', 'zhf')
			}
			if (lang === 'zhf') {
				this.$i18n.locale = 'en'
				localStorage.setItem('lang', 'en')
			}
			if (lang === 'en') {
				this.$i18n.locale = 'kor'
				localStorage.setItem('lang', 'kor')
			}
			if (lang === 'kor') {
				this.$i18n.locale = 'zh'
				localStorage.setItem('lang', 'zh')
			}
		},
		routerTo(path) {
			this.$router.push(path)
		},
		// 用户协议内容id获取
		getAritcleId() {
			this.$post2('Article/api/share/getUserRegisterId', {}).then(res => {
				this.articleId = res.id;
			});
		},
		// 用户协议跳转
		// viewUserRegisterProtocol() {
		// 	this.$router.push({
		// 		path: '/viewArticle',
		// 		query: { id: this.articleId }
		// 	});
		// },
		// isPhoneNumber(phone) {
		// 	if (!/^1[3456789]\d{9}$/.test(phone)) {
		// 		return false;
		// 	}
		// 	return true;
		// },
		// 获取短信验证码
		smscode() {
			// if (!this.isPhoneNumber(this.dataForm.phone)) {
			// 	// this.$toast('请输入正确的手机号');
			// 	this.$toast(this.$t('login.PleaseEnterRightPhoneNumber'));
			// 	return;
			// }
			console.log(this.phoneCountry)
			
			if (this.codeSending) {
				return;
			}
		
			this.theTime = 60;
			this.codeSending = true;
			this.doCountDown();
		
			this.$post2('Security/Index/phonecode', {
				phone: this.dataForm.phone,
				nation: this.phoneCountry,
				op:'register'
			}).then(res => {
				this.dataForm.sid = res.sid;
			});
		},
		// 短信验证码倒计时
		doCountDown() {
			if (this.theTime > 1) {
				this.theTime--;
				this.codeTimer = setTimeout(() => {
					this.doCountDown();
				}, 1000);
			} else {
				this.codeSending = false;
				this.haveValue = true;
			}
		},
		checkForm() {
			if (this.dataForm.code == '') {
				// this.$toast('请输入验证码！');
				this.$toast(this.$t('login.PleaseEnterVerificationCode'));
				return false;
			}
			
			if (this.dataForm.password == '' || this.dataForm.password.length < 8) {
				// this.$toast('请输入密码！');
				// this.$toast(this.$t('login.PleaseEnterYourPassword'));
				// this.showAlert("密码不能为空且不能小于8位");
				this.$toast(this.$t('system.passwordMin8'))
				return false;
			}
			
			if (this.dataForm.repassword == '') {
				// this.$toast('请输入确认密码！');
				this.$toast(this.$t('sPay.ConfirmTransactionPassword'));
				return false;
			}
			
			if (this.dataForm.password !== this.dataForm.repassword) {
				// this.$toast('两次密码不一致！');
				this.$toast(this.$t('sPwd.twoPasswordsDoNotMatch'));
				return false;
			}
			
			if (this.dataForm.refcode == '') {
				// this.$toast('请输入邀请码！');
				this.$toast(this.$t('login.PleaseEnterYourCode'));
				return false;
			}
			
			if (this.dataForm.agree == false) {
				// this.$toast.fail('请勾选《用户协议》');
				this.$toast(this.$t('system.PleaseCheckUserAgreement'));
				return false;
			}else {
				this.dataForm.agree = 1;
			}
			
			return true
		},
		doRegister(reigserUlr) {
			if(!this.registerIng){
				this.registerIng=true
				if (!this.checkForm()) {
					this.registerIng=false
					return
				}
				let newdata = {
					nation: this.phoneCountry,
					username: this.dataForm.phone, //用户名
					password: this.dataForm.password, // 密码
					repassword: this.dataForm.repassword, // 确认密码
					refcode: this.dataForm.refcode, // 邀请码
					sid: this.dataForm.sid, //短信验证码id
					code: this.dataForm.code, //验证码
					email: this.dataForm.phone, //手机号
					agree: this.dataForm.agree, //同意协议
				}
				let toast=this.$toast.loading({
					duration: 0, // 持续展示 toast
					forbidClick: true,
					message: this.$t('system.Loading'),
				});
				
				this.$post2(reigserUlr, newdata)
				.then(res => {
					this.registerIng=false
					// if(res.code == 200){
					this.$toast(this.$t('system.RegistrationSuccessfulReturntoLogin'));
					
					this.$post2("user/api/passport/loginwithpwd", {
						username: this.dataForm.phone,
						refcode: '',
						type: 'email',
						password: this.dataForm.repassword,
					})
					.then((res) => {
						toast.clear();
						this.$saveLoginSession(res);
						this.$router.push({
							path: "/newHome",
							query: {
								ifhave: false,
							},
						});
					})
					.catch((e) => {
						toast.clear();
						this.$router.push('login');
					});

				})
				.catch(e => {
					toast.clear();
					this.registerIng=false
					this.$toast.fail(e);
				});
			}
		},

		//获取logo图
		GetSystemImgList() {
			this.$post2('User/Api/Passport/GetSystemImgList', {})
				.then(res => {
					localStorage.setItem('logo_img',res.list[1].url);
					localStorage.setItem('avatar_img',res.list[0].url);
					this.logo_img = res.list[1].url;
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>

<style lang="less">
// ------20200409-----
@import url('../../../assets/css/login.less');
.registBox {
	min-height: 100%;
	font-family: PingFangSC-Regular;
	background-color: #FFFFFF;
	/* .head {
		display: flex;
		margin: 0 0.72rem 0 0.72rem;
		padding-top: 0.28rem;
		align-items: center;
		justify-content: space-between; */
		.langBtn {
			.icon {
				width: 0.36rem;
				height: 0.3352rem;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					display: block;
					width: 100%;
				}
			}
		}
		.zhuce {
			.txt {
				text-align: center;
				line-height: 0.51rem;
				color: #EFBA0D;
				font-size: 0.32rem;
				font-weight: 600;
			}
		}
	/* } */
	.registBody {
		padding: 0.95rem 0.67rem 0.4rem 0.71rem;
		// background: #ffffff;
		.headIcon {
			width: 3.15rem;
			height: 3.15rem;
			margin: auto;
			img {
				width: 100%;
				height: 100%;
				display: block;
				margin: 0 auto;
			}
		}
		.agreelaw {
			font-size: 0.24rem;
			// font-family: PingFang SC;
			// font-weight: 400;
			color: #c3c5c8;
			padding-top: 0.4rem;
			.mainColor1{
				color: #EFBA0D;
				// border-bottom: 1px solid #90C4FF;
			}
		}
		.tologin {
			padding-top: 0.2rem;
			font-size: 0.24rem;
			text-align: center;
			color: #999;
			padding-bottom: 0.3rem;
		}
	}
	.registerPage {
		color: #FFFFFF;
	    padding: 0.2rem 0.2rem 0.4rem;
	}
	.titleBtnStyle {
	    background: #FC9216;
	    border: 0px;
	    border-radius: 6px;
	    width: 1.6rem;
	    float: right;
	}
}
.login-dex {
	// margin-top: 1.6rem !important;
	padding-top: 1.44rem;
}

/* 我同意字体在有的分辨率下显示不全的问题 */
</style>
